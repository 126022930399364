import GLightbox from "glightbox";

const lightbox = GLightbox({
  selector: ".lightbox",
  moreText:
    window.location.href.indexOf("/en/") > -1 ? "See more" : "Leggi tutto",
});

let isFetching = false;
let currentPage = 1;
let isDone = false;
let toReload = false;

const imgContainer = document.getElementById("gallery-container");
const loader = document.getElementById("loader");

const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

// Functions
const fetchNext = () => {
  const maxIndex = lightbox.elements.length - 1;
  loader.classList.add("show");
  currentPage++;
  isFetching = true;
  let url = window.location.href;
  url = updateQueryStringParameter(url, "partial", 1);
  url = updateQueryStringParameter(url, "page", currentPage);
  fetch(url)
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      if (data.trim().length < 1) {
        isDone = true;
      }
      updateDom(data);
      isFetching = false;
      if (!document.getElementById("glightbox-body")) {
        lightbox.reload();
      } else {
        toReload = true;
        loader.classList.remove("show");
        Array.from(document.querySelectorAll(".lightbox"))
          .slice(maxIndex + 1)
          .forEach((el) => {
            lightbox.insertSlide(el);
          });
      }
    });
};

const updateDom = (images) => {
  imgContainer.innerHTML += images;
};

window.addEventListener("scroll", () => {
  // Do not run if currently fetching
  if (isFetching) return;
  if (isDone) return;

  // Scrolled to bottom
  if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    fetchNext();
  }
});

lightbox.on("slide_changed", (data) => {
  const slideIndex = data.current.index;
  const maxIndex = lightbox.elements.length - 1;

  // console.log(slideIndex, maxIndex);
  // console.log(isFetching, isDone);

  if (isFetching) return;
  if (isDone) return;
  if (maxIndex !== slideIndex && maxIndex - 1 !== slideIndex) return;

  // console.log("fetching");
  fetchNext();
});

// console.log(lightbox);

lightbox.on("close", () => {
  if (toReload) {
    lightbox.reload();
    toReload = false;
  }
});
